import React from "react";
import { Routes, Route } from "react-router-dom";
import LoginLayout from "../layout/LoginLayout";
import Login from "../Screens/login/Login";
export default function AuthRoutes() {
  return (
    <Routes>
      <Route element={<LoginLayout />}>
        <Route index element={<Login />} />
        <Route path="/login" element={<Login />} />
        {/* <Route path='/forgetpass' element={<ForgotPassword/>} /> */}
      </Route>
    </Routes>
  );
}
