import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";
import { fontSize } from "@mui/system";
import React, { useState } from "react";

import { Mainimg, Loginimg, TreeOne } from "../Assets/Icon";
import Login from "../Screens/login/Login";

export default function LoginLayout() {
  const [forget, setforget] = useState("login");
  return (
    <>
      <Grid container xs={12} style={{}}>
        <Grid
          item
          lg={6}
          md={6}
          sm={0}
          xs={0}
          sx={{ display: { lg: "block", md: "block", sm: "none", xs: "none" } }}
        >
          <Mainimg
            style={{
              position: "relative",
              width: "50vw",
              height: "100vh",
            }}
          />
        </Grid>
        <Grid
          container
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={{
            display: { lg: "block", md: "block", sm: "none", xs: "none" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "100vh",
            // background: "#43b4ff",
            background:
              "linear-gradient(350deg,  #FFFFFF, 5%, #2D71BC)",
            borderTopLeftRadius: "180px",
            borderBottomLeftRadius: "180px",
          }}
        >
          <Grid
            container
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{
              display: { lg: "none", md: "none", sm: "block", xs: "block" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: "100vh",
              // border: "1px solid"
              // background: "#43b4ff",
              background: "transparent",
            }}
          >
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                // border: "1px solid",
                width: "100vw",
              }}
            >
              <>
                <span style={{ display: "flex", justifyContent: "center" }}>
                  <TreeOne style={{ width: "200px", height: "60px" }} />
                </span>
                {forget === "login" && (
                  <>
                    <Login setforget={setforget} />
                  </>
                )}
                {forget === "forget" && <div>{/* <ForgotPassword /> */}</div>}
              </>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
const style = {
  text: {
    margin: "0px 0px 5px 0px",
    fontSize: "14px",
  },
};
