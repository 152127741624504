import { Autocomplete, Button, Grid, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Loginimg } from '../Assets/Icon'
import styled from '@emotion/styled';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function Profile() {


    const AppCode = global.$userData.logindata.AppCode;
    const ContactId = global.$userData.logindata.ContactId;
    const AccountsId = global.$userData.logindata.AccountsId;
    const Navigate = useNavigate();

    //usestate
    const [Designation, setDesignation] = useState([]);
    const [Department, SetDepartment] = useState([])
    const [Company, SetCompany] = useState([])
    const [Firstname, SetFirstname] = useState("")
    const [MobileNumber, SetMobileNumber] = useState("")
    const [Email, SetEmail] = useState("")


    //dropdown
    const [DepartmentDrop, setDepartmentDrop] = useState([]);
    const [DesignationDrop, setDesignationDrop] = useState([]);
    const [CompanyDrop, setCompanyDrop] = useState([]);

    //dropdownapi
    const DesignationList = async () => {
        const response = await global.$axios.get(
            `/customerdesignation/cdesignation/${AppCode}`
        );
        setDesignationDrop(response.data.Message);
    };

    const DepartmentList = async () => {
        const response = await global.$axios.get(
            `/customerdepartment/cdepartment/${AppCode}`
        );
        setDepartmentDrop(response.data.Message);
    };
    const CompanyList = async () => {
        const response = await global.$axios.get(
            `/company/getcompanylist/${AppCode}`
        );
        // console.log("CompanyList", response.data);
        setCompanyDrop(response.data.message);
    };
    //validation

    //Mobile digit Validate
    const [MCount, setMCount] = useState(false);
    const MobileVali = (e) => {
        if (e.length !== 10) {
            setMCount(true);
        } else {
            setMCount(false);
        }
    };
    // Mobile Exist Validate
    const [MobileErr, setMobileErr] = useState(0);
    const MobileVal = async (MobileNumber) => {
        if (MobileNumber != editdata.MobileNumber) {
            const response = await global.$axios
                .get(`/company/contact/validatemobile/${AppCode}/${MobileNumber}`)
                .then(async (response) => {
                    setMobileErr(response.data.Message);
                })
                .catch((e) => {
                    console.error("Contact Mobile Exist Issue", e);
                });
        } else {
            setMobileErr(0)
        }

    };

    // Mail Exist Validate
    const [MailError, setMailError] = useState(0);
    const EMailValid = async (MailId) => {
        if (MailId != editdata.EmailId) {
            const response = await global.$axios
                .get(`/company/contact/validatemail/${AppCode}/${MailId}`)
                .then(async (response) => {
                    setMailError(response.data.Message);
                })
                .catch((e) => {
                    console.error("Contact Mail Exist Issue", e);
                });
        } else {
            setMailError(0)
        }

    };

    //Mail Format Validate
    const [NoValidEmail, setNoValidEmail] = useState(false);
    const MailValidate = (val) => {
        if (val.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
            setNoValidEmail(false);
        } else {
            setNoValidEmail(true);
        }
    };
    const [editdata, Seteditdata] = useState([])

    //editview
    const editview = async () => {
        const response = await global.$axios.get(`customerticket/viewcontact/${ContactId}`)
            .then(async (response) => {
                var shortresponse = response.data.message
                if (response.data.status == 200) {
                    SetFirstname(shortresponse.FirstName)
                    SetMobileNumber(shortresponse.MobileNumber)
                    SetEmail(shortresponse.EmailId)
                    setDesignation(shortresponse.customerdesignations)
                    SetCompany(shortresponse.account)
                    SetDepartment(shortresponse.customerdepartments)
                    Seteditdata(shortresponse)

                }
            }).catch((e) =>
                toast.error("editviewapi error", e))
    }
    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });
    const imageupdate = async (files) => {
        const chosenFiles = Array.prototype.slice.call(files.target.files)
        var formData = new FormData();
        formData.append("file", chosenFiles[0])
        const response = await global.$axios
            .post("ticket/upload", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Accept: "application/json",
                },
            })
            .then(async (response) => {
                if (response.data.Status == "200") {
                    imageupload(response.data.Message)
                }
            })
    }
    const imageupload = async (Attachemnetlink) => {
        const response = await global.$axios.post("customerticket/updateimage", {
            ImagePathLink: Attachemnetlink,
            ContactId: ContactId,
            AppCode: AppCode
        })
            .then(async (response) => {
                toast.success("Profile Picture Changed Sucessfully")
                editview()
            })
            .catch((e) => {
                console.error("Imageuplode", e)
            })
    }
    const editapi = async () => {
        if (Firstname &&
            MobileNumber.length == 10 &&
            Email &&
            Designation &&
            Department &&
            !NoValidEmail &&
            !MCount) {
            const response = await global.$axios.post("/customerticket/updatecontact", {
                FirstName: Firstname,
                Department: Department?.CDepartmentId,
                MobileNumber: MobileNumber,
                EmailId: Email,
                Designation: Designation.CDesignationId,
                AppCode: AppCode,
                ContactId: ContactId
            })
                .then(async (response) => {
                    if (response.data.status == 200) {
                        setTimeout(() => {
                            Navigate(-1)
                        }, 1200)
                        toast.success("Profile Update Sucessfully")
                    } else {
                        toast.error("Something Went Wrong")
                    }
                })
        } else {
            toast.error("Required Field Are Missing")
        }


    }
    useEffect(() => {
        DesignationList()
        DepartmentList()
        editview()
    }, [])
    return (
        <Grid container item style={{ backgroundColor: "rgba(236, 244, 251, 1)", padding: "10px", borderRadius: "10px" }}>
            <Grid container xs={12} style={{ color: "#2D71BC", fontSize: "20px", }}>
                My Profile
            </Grid>
            <Grid container md={3} xs={12} style={{ display: "flex", flexDirection: "column", alignItems: "center", }}>
                {/* <Loginimg style={{ marginTop: "10px", width: "80%" }} /> */}
                <div style={{ width: "130px", borderRadius: "30px", marginTop: "30px", alignItems: "center", display: "flex", justifyContent: "center", height: "180px" }}>
                    {editdata.ImagePathLink != "" ? <img src={editdata.ImagePathLink}
                        style={{ maxWidth: 180, maxHeight: 200, borderRadius: "30px", }}
                        alt="Thumb" /> : <Loginimg style={{ marginTop: "10px", width: "80%" }} />}

                </div>
                <Button component="label" variant='outlined' style={{ maxHeight: "30px", minHeight: "22px", marginTop: "20px" }}>
                    Update Photo
                    <VisuallyHiddenInput type="file" onChange={(e) => imageupdate(e)} />
                </Button>
            </Grid>
            <Grid container item md={9} xs={12} style={{}}>
                <Grid container xs={12} style={{}}>
                    <Grid item md={6} xs={12} style={{ padding: "5px", marginTop: "15px" }}>
                        <TextField
                            variant='outlined'
                            label="Name*"
                            size='small'
                            fullWidth
                            value={Firstname}
                            style={{ background: "rgba(205, 229, 255, 1)", border: 0, borderColor: "transparent", width: "90%" }} sx={{
                                border: "1px solid transparent",
                            }}
                            onChange={(e) => SetFirstname(e.target.value)}
                        />
                    </Grid>
                    <Grid item md={6} xs={12} style={{ padding: "5px", marginTop: "15px" }}>
                        <TextField
                            variant='outlined'
                            label="Mobile Number"
                            size='small'
                            fullWidth
                            required
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: "10" }}
                            value={MobileNumber}
                            style={{ background: "rgba(205, 229, 255, 1)", border: 0, borderColor: "transparent", width: "90%" }}
                            onKeyUp={(e) => {
                                e.target.value !== "" && MobileVali(e.target.value);
                                MobileVal(e.target.value);
                            }}
                            onChange={(e) => SetMobileNumber(e.target.value)}

                        />
                        <div>
                            {
                                MCount === true ? (
                                    <span style={{ color: "darkred", fontSize: "8" }}>
                                        10 Digits Required
                                    </span>
                                ) : MobileErr === 1 ? (
                                    <span style={{ color: "darkred", fontSize: "8" }}>Already Exist</span>
                                ) : (
                                    <span style={{ color: "transparent" }}>
                                        Already Exist
                                    </span>
                                )
                            }
                        </div>
                    </Grid>
                </Grid>

                <Grid container xs={12} style={{}}>
                    <Grid item md={6} xs={12} style={{ padding: "5px", marginTop: "0px" }}>
                        <TextField
                            variant='outlined'
                            label="Email*"
                            size='small'
                            fullWidth
                            value={Email}
                            style={{ background: "rgba(205, 229, 255, 1)", border: 0, borderColor: "transparent", width: "90%" }}
                            onChange={(e) => SetEmail(e.target.value)}
                            onKeyUp={(e) => {
                                e.target.value != "" && EMailValid(e.target.value);
                                MailValidate(e.target.value);
                            }}

                        />
                        <div>
                            {
                                MailError === 1 ? (
                                    <span style={{ color: "darkred" }}>Already Exist</span>
                                ) : NoValidEmail ? (
                                    <span style={{ color: "darkred" }}>Not Valid Mail</span>
                                ) : (
                                    <span style={{ color: "transparent" }}>
                                        Already Exist
                                    </span>
                                )
                            }
                        </div>
                    </Grid>
                    <Grid item md={6} xs={12} style={{ padding: "5px", marginTop: "0px" }}>
                        <Autocomplete
                            id="departement drop"
                            fullWidth
                            options={CompanyDrop}
                            value={Company}
                            disabled
                            getOptionLabel={(option) => option.CompanyName || ""}
                            size='small'
                            style={{ backgroundColor: "rgba(205, 229, 255, 1)", width: "90%" }}
                            renderInput={(params) => (
                                <TextField {...params} label="Company Name*" />
                            )}
                            onChange={(event, newvalue) => {
                                SetCompany(newvalue)

                            }}

                        />
                    </Grid>
                </Grid>
                <Grid container xs={12} style={{}}>
                    <Grid item md={6} xs={12} style={{ padding: "5px", marginTop: "5px" }}>
                        <Autocomplete
                            id="designation drop"
                            fullWidth
                            options={DesignationDrop}
                            value={Designation}
                            disableClearable
                            getOptionLabel={(option) => option.CDesignationName || ""}
                            size='small'
                            style={{ backgroundColor: "rgba(205, 229, 255, 1)", width: "90%" }}
                            renderInput={(params) => (
                                <TextField {...params} label="Designation*" />
                            )}
                            onChange={(event, newvalue) => {
                                setDesignation(newvalue)

                            }}

                        />
                    </Grid>
                    <Grid item md={6} xs={12} style={{ padding: "5px", marginTop: "0px" }}>
                        <Autocomplete
                            id="departement drop"
                            fullWidth
                            options={DepartmentDrop}
                            value={Department}
                            disableClearable
                            getOptionLabel={(option) => option.CDepartmentName || ""}
                            size='small'
                            style={{ backgroundColor: "rgba(205, 229, 255, 1)", width: "90%" }}
                            renderInput={(params) => (
                                <TextField {...params} label=" Department*" />
                            )}
                            onChange={(event, newvalue) => {
                                SetDepartment(newvalue)
                            }}
                        />
                    </Grid>

                </Grid>
                <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "flex-end", marginTop: "10px" }}>
                    <Button variant='contained' style={{ maxHeight: "30px", minHeight: "22px", display: "flex", }}
                        onClick={() => editapi()}
                    >
                        Update Profile
                    </Button>
                </Grid>

            </Grid>
            <ToastContainer
                position="top-right"
                autoClose={1500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                fontSize={"14px"}
                fontWeight={"600"}
                color={"#232323"}
            />
        </Grid>
    )
}

export default Profile