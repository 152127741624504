import { Box, Button, Grid, IconButton, Modal } from "@mui/material";
import React from "react";
import { CloseIcon } from "../../Assets/Icon";
import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ReOpen = ({ ReopenModal, setReopenModal, TicketId }) => {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  const Navigate = useNavigate();

  const handleClose = () => setReopenModal(false);
  const [Comment, setComment] = useState("");

  const ReopenPost = async () => {
    if (Comment == "") {
      toast.error("Reopen Reason Required");
    } else {
      const response = await global.$axios
        .post("/ticket/reopen", {
          ReopenReason: Comment,
          AppCode: AppCode,
          TicketId: TicketId,
        })

        .then(async (response) => {
          if (response.data.Status === 200) {
            toast.success("Ticket Reopened & Moved To Progress");
            setTimeout(() => {
              handleClose();
              setTimeout(() => {
                Navigate(-1);
              }, 1500)
            }, 1000);
          } else {
            toast.error("Something Went Wrong");
          }
        })
        .catch((e) => {
          console.error("Reopen Post Issue", e);
        });
    }

  };
  return (
    <div>
      <Modal
        open={ReopenModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "0px 0px 10px 0px",
            }}
          >
            <p style={{ margin: "0px", color: "#0071BC", fontWeight: 500 }}>
              Reopen Ticket
            </p>
            <IconButton
              onClick={() => handleClose()}
              style={{ padding: "0px" }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <p style={{ margin: "0px 0px 5px 0px", fontSize: "14px" }}>
            Reason For Reopening The Ticket
          </p>
          <textarea
            value={Comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Start Here ..."
            style={{
              width: "100%",
              border: "1px solid gray",
              background: "#f5f5f5",
              borderRadius: "10px",
              padding: "10px",
              fontSize: "18px",
              fontWeight: "200",
              height: "80px",
              outline: "none",
            }}
          ></textarea>
          <div style={{ margin: "10px 0px" }}>
            <center>
              <Button
                size="small"
                variant="contained"
                sx={{
                  minHeight: "22px",
                  maxHeight: "22px",
                  padding: "12px",
                }}
                onClick={() => ReopenPost()}
              >
                Submit
              </Button>
            </center>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ReOpen;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  background: "white",
  borderRadius: "10px",
  outline: "none",
  boxShadow: 24,
  pt: 2,
  pb: 3,
  pl: 4,
  pr: 4,
};
