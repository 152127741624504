import React from "react";
import { Routes, Route } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import LoginLayout from "../layout/LoginLayout";
import CreateTicket from "../Screens/New_ticket/CreateTicket";
import TicketStatus from "../Screens/TicketStatus";
import ViewTicket from "../Screens/StatusTable/TicketView";
import Teamstatus from "../Screens/TeamTickets/Teamstatus";
import Profile from "../Screens/Profile";


const AppRoutes = () => {
  return (
    <>
      <Routes>
        <Route element={<MainLayout />}>
          <Route index element={<CreateTicket />} />
          <Route path="/CreateTicket" element={<CreateTicket />} />
          <Route path="/Ticketstatus" element={<TicketStatus />} />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/Ticketstatus/TicketView" element={<ViewTicket />} />
          {global?.$userData?.logindata?.Designation === "1" ||
            global?.$userData?.logindata?.Designation === "2" ||
            global?.$userData?.logindata?.Designation === "3" ||
            global?.$userData?.logindata?.Designation === "4" ? (
            <Route path="/TeamTicket" element={<Teamstatus />} />) : ""}
          <Route path="/TeamTicket/TicketView" element={<ViewTicket />} />


        </Route>
      </Routes>

    </>
  );
};

export default AppRoutes;
