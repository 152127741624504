import { Autocomplete, Grid, TextField, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  CompletedIcon,
  NewIcon,
  ProgressIcon,
  RightArrowIcon,
} from "../../Assets/Icon";
import All from "../StatusTable/All";
import Newtable from "../StatusTable/Newtable";
import Progress from "../StatusTable/Progress";
import Completed from "../StatusTable/Completed";
import TAll from "./TAll";
import TNewtable from "./TNewtable";
import TProgress from "./TProgress";
import TCompleted from "./TCompleted";

function Teamstatus() {
  const AppCode = global.$userData.logindata.AppCode;
  const ContactId = global.$userData.logindata.ContactId;
  const AccountsId = global.$userData.logindata.AccountsId;

  const [head, setHead] = useState("All");
  const [ticketCount, setTicketCount] = useState([]);
  const [TicketStatus, setTicketStatus] = useState(4);

  let TeamTicket = localStorage.getItem("TeamTicket");
  let TeamDrop = localStorage.getItem("Teamdrop");
  let TeamDrop1 = JSON.parse(TeamDrop);

  //userdrop
  const [DropData, SetDropData] = useState([]);
  const [Dropvalue, SetDropValue] = useState(
    TeamDrop1 !== null ? TeamDrop1 : { ContactId: "All", FirstName: "All" }
  );

  //valuepass
  const [Accountid, SetAccountid] = useState([]);
  //allmao
  const [Allmap, SetAllmap] = useState([]);

  //api
  const Users = async () => {
    const response = await global.$axios
      .post(`customerticket/contactname`, {
        AppCode: AppCode,
        AccountsId: AccountsId,
      })
      .then(async (response) => {
        SetDropData([
          ...[{ ContactId: "All", FirstName: "All" }],
          ...response?.data?.Message,
        ]);
        SetAccountid(
          TeamDrop1 == null || TeamDrop1?.ContactId == "All"
            ? response?.data?.Message?.map((e) => e?.ContactId)
            : [TeamDrop1?.ContactId]
        );
        SetAllmap(response.data.Message);
        Ticketcount(
          TeamDrop1 == null || TeamDrop1?.ContactId == "All"
            ? response?.data?.Message?.map((e) => e?.ContactId)
            : [TeamDrop1?.ContactId]
        );
      })
      .catch((e) => {
        console.error("Users", e);
      });
  };

  const Ticketcount = async (id) => {
    const response = await global.$axios
      .post(`/customerticket/CusCount/${AppCode}`, {
        ContactId: id,
      })
      .then(async (response) => {
        setTicketCount(response?.data?.msg);
      })
      .catch((e) => {
        console.error("Ticketcount", e);
      });
  };
  useEffect(() => {
    Users();
    setHead(TeamTicket != null ? TeamTicket : "All");
  }, []);
  return (
    <>
      <Grid container style={{ alignItems: "center" }}>
        <Tooltip title="ALL">
          <span
            style={{
              color: "#0071BC",
              fontWeight: 600,
              fontSize: "16px",
              margin: "0px",
              padding: "0px",
              cursor: "pointer",
            }}
            onClick={() => {
              setHead("All");
              localStorage.setItem("TeamTicket", "All");
            }}
          >
            Team Tickets
          </span>
        </Tooltip>
        <span style={{ padding: "0px 5px", color: "#0071BC" }}>/</span>{" "}
        <span
          style={{
            color: "#0071BC",
            fontWeight: 600,
            fontSize: "22px",
            margin: "0px",
            padding: "0px",
            color: "red",
          }}
        >
          {head}
        </span>
      </Grid>
      <Grid container style={{ marginTop: "10px" }}>
        <Grid item xs={2.8}>
          <Autocomplete
            value={Dropvalue}
            size="small"
            fullWidth
            disableClearable
            id="controllable-states-demo"
            options={DropData}
            getOptionLabel={(option) => option.FirstName || ""}
            renderInput={(params) => <TextField {...params} label="Users" />}
            onChange={(event, newvalue) => {
              localStorage.setItem("Teamdrop", JSON.stringify(newvalue));
              SetDropValue(newvalue);
              if (newvalue.ContactId === "All") {
                SetAccountid(Allmap.map((e) => e.ContactId));
                Ticketcount(Allmap.map((e) => e.ContactId));
              } else {
                SetAccountid([newvalue.ContactId]);
                Ticketcount([newvalue.ContactId]);
              }
            }}
          />
        </Grid>
      </Grid>
      <div style={{ marginTop: "10px" }}>
        <Grid
          container
          xs={12}
          style={{
            display: "flex",
            // justifyContent: "space-between",
            margin: "20px 0px 10px 0px",
            gap: "20px",
            // background: "red"
          }}
        >
          <Grid
            item
            lg={1.9}
            md={3.9}
            sm={5.9}
            xs={12}
            style={{
              background: "#FFFFFF",
              border: "1px solid rgb(0,0,0,0.1)",
              borderRadius: "5px",
              boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
              marginBottom: "5px",
              cursor: "pointer",
              // backgroundColor: 'red'
            }}
            onClick={() => {
              setHead("New");
              setTicketStatus(1);
              localStorage.setItem("TeamTicket", "New");
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #C2C2C2",
                padding: "0px 5px",
              }}
            >
              <p style={styles.tablehdr}>New</p>
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                  alignItems: "center",
                }}
              >
                <NewIcon />
                <RightArrowIcon />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "10px",
              }}
            >
              <p style={{ margin: "0px", fontSize: "12px", fontWeight: 500 }}>
                Total
              </p>

              <p style={{ margin: "0px", fontSize: "12px" }}>
                {/* statusCount.New */}
                {ticketCount.statusCount?.New}
              </p>
            </div>
          </Grid>

          <Grid
            item
            lg={1.9}
            md={3.9}
            sm={5.9}
            xs={12}
            style={{
              background: "#FFFFFF",
              border: "1px solid rgb(0,0,0,0.1)",
              borderRadius: "5px",
              boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
              marginBottom: "5px",
              cursor: "pointer",
              // marginLeft: "10px",
            }}
            onClick={() => {
              setHead("Progress");
              setTicketStatus(2);
              localStorage.setItem("TeamTicket", "Progress");
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #C2C2C2",
                padding: "0px 5px",
              }}
            >
              <p style={styles.tablehdr}>Progress</p>
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                  alignItems: "center",
                }}
              >
                <ProgressIcon />
                <RightArrowIcon />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "10px",
              }}
            >
              <p style={{ margin: "0px", fontSize: "12px", fontWeight: 500 }}>
                Total
              </p>

              <p style={{ margin: "0px", fontSize: "12px" }}>
                {ticketCount?.statusCount?.Progress}
              </p>
            </div>
          </Grid>

          <Grid
            item
            lg={1.9}
            md={3.9}
            sm={5.9}
            xs={12}
            style={{
              background: "#FFFFFF",
              border: "1px solid rgb(0,0,0,0.1)",
              borderRadius: "5px",
              boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
              marginBottom: "5px",
              cursor: "pointer",
            }}
            onClick={() => {
              setHead("Completed");
              setTicketStatus(3);
              localStorage.setItem("TeamTicket", "Completed");
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #C2C2C2",
                padding: "0px 5px",
              }}
            >
              <p style={styles.tablehdr}>Completed</p>
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                  alignItems: "center",
                }}
              >
                <CompletedIcon />
                <RightArrowIcon />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "10px",
              }}
            >
              <p style={{ margin: "0px", fontSize: "12px", fontWeight: 500 }}>
                Total
              </p>

              <p style={{ margin: "0px", fontSize: "12px" }}>
                {ticketCount?.statusCount?.Completed}
              </p>
            </div>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          lg={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <Grid item md={10} xs={12} sm={10} lg={10}>
            <p style={{ margin: "0px", fontSize: "16px", fontWeight: 600 }}>
              {head}
              <span
                style={{
                  paddingLeft: "5px",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
              >
                List
              </span>
            </p>
          </Grid>
          {head === "All" && <TAll Accountid={Accountid} />}
          {head === "New" && <TNewtable Accountid={Accountid} />}
          {head === "Progress" && <TProgress Accountid={Accountid} />}
          {head === "Completed" && <TCompleted Accountid={Accountid} />}
        </Grid>
      </div>
    </>
  );
}

export default Teamstatus;
const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
  activehdr: {
    color: "#0071BC",
    fontWeight: 600,
    fontSize: "20px",
    margin: "0px",
    padding: "0px",
    // borderBottom: "1px solid #0071BC",
  },
  unactivehdr: {
    color: "#0071BC",
    fontWeight: 600,
    fontSize: "16px",
    margin: "0px",
    padding: "0px",
  },
  tablehdr: {
    margin: "0px",
    fontSize: "14px",
  },
};
