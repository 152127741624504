import { Box, Button, Grid, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
// import SearchIcon from "@mui/icons-material/Search";
import {
  RightArrowIcon,
  NewIcon,
  ProgressIcon,
  CompletedIcon,
} from "../Assets/Icon";
import { Link, useNavigate } from "react-router-dom";
import Newtable from "./StatusTable/Newtable";
import Progress from "./StatusTable/Progress";
import Completed from "./StatusTable/Completed";
import All from "./StatusTable/All";

export default function TicketStatus() {
  const AppCode = global.$userData.logindata.AppCode;
  const ContactId = global.$userData.logindata.ContactId;

  //usestate
  const [New, setNew] = useState([]);
  const [ticketCount, setTicketCount] = useState([]);
  const [slacount, setSlaCount] = useState([]);
  const Navigate = useNavigate();
  const [TicketStatus, setTicketStatus] = useState(4);
  const [head, setHead] = useState("All");

  //LocalStorage
  let TableView = localStorage.getItem("TableView");

  function UpdatedFullName(fields) {
    return `${fields.row.updated.UserFirstName || ""} ${
      fields.row.updated.UserLastName || ""
    }`;
  }
  function AssignedFullName(fields) {
    return `${fields.row.created.UserFirstName || ""} ${
      fields.row.created.UserLastName || ""
    }`;
  }

  const TicketCount = async () => {
    const response = await global.$axios.get(
      `customerticket/Cuscreateticketcount/${AppCode}/${ContactId}`
    );
    // console.log(response, "TicketCount");
    setTicketCount(response.data.msg);
  };

  useEffect(() => {
    TicketCount();
    setHead(TableView != null ? TableView : "All");
  }, []);

  return (
    <div>
      <Grid
        container
        spacing={0}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // gap: "20px"
        }}
      >
        <Grid item style={{ margin: "5px 0px 0px 0px" }}>
          <Tooltip title="ALL">
            <span
              style={{
                color: "#0071BC",
                fontWeight: 600,
                fontSize: "16px",
                margin: "0px",
                padding: "0px",
                cursor: "pointer",
              }}
              onClick={() => {
                setHead("All");
                localStorage.setItem("TableView", "All");
              }}
            >
              Ticket Status
            </span>
          </Tooltip>
          <span style={{ padding: "0px 5px", color: "#0071BC" }}>/</span>{" "}
          <span
            style={{
              color: "#0071BC",
              fontWeight: 600,
              fontSize: "22px",
              margin: "0px",
              padding: "0px",
              color: "red",
            }}
          >
            {head}
          </span>
        </Grid>
        {/* <Grid item style={{ margin: "5px 0px 0px 0px" }}>
                        <Button
                            onClick={() => Navigate("/app/AddTicket")}
                            style={{
                                textTransform: "none",
                                maxHeight: "22px",
                                minHeight: "22px",
                                padding: "12px",
                                fontSize: "12px",
                            }}
                            variant="contained"
                        >
                            + Add More Ticket
                        </Button>
                    </Grid> */}
      </Grid>

      <div style={{ marginTop: "10px" }}>
        <>
          <Grid
            container
            xs={12}
            style={{
              display: "flex",
              // justifyContent: "space-between",
              margin: "20px 0px 10px 0px",
              gap: "20px",
              // background: "red"
            }}
          >
            <Grid
              item
              lg={1.9}
              md={3.9}
              sm={5.9}
              xs={12}
              style={{
                background: "#FFFFFF",
                border: "1px solid rgb(0,0,0,0.1)",
                borderRadius: "5px",
                boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
                marginBottom: "5px",
                cursor: "pointer",
                // backgroundColor: 'red'
              }}
              onClick={() => {
                setHead("New");
                setTicketStatus(1);
                localStorage.setItem("TableView", "New");
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #C2C2C2",
                  padding: "0px 5px",
                }}
              >
                <p style={styles.tablehdr}>New</p>
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    alignItems: "center",
                  }}
                >
                  <NewIcon />
                  <RightArrowIcon />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "10px",
                }}
              >
                <p style={{ margin: "0px", fontSize: "12px", fontWeight: 500 }}>
                  Total
                </p>

                <p style={{ margin: "0px", fontSize: "12px" }}>
                  {ticketCount?.statusCount?.New}
                </p>
              </div>
            </Grid>

            <Grid
              item
              lg={1.9}
              md={3.9}
              sm={5.9}
              xs={12}
              style={{
                background: "#FFFFFF",
                border: "1px solid rgb(0,0,0,0.1)",
                borderRadius: "5px",
                boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
                marginBottom: "5px",
                cursor: "pointer",
                // marginLeft: "10px",
              }}
              onClick={() => {
                setHead("Progress");
                setTicketStatus(2);
                localStorage.setItem("TableView", "Progress");
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #C2C2C2",
                  padding: "0px 5px",
                }}
              >
                <p style={styles.tablehdr}>Progress</p>
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    alignItems: "center",
                  }}
                >
                  <ProgressIcon />
                  <RightArrowIcon />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "10px",
                }}
              >
                <p style={{ margin: "0px", fontSize: "12px", fontWeight: 500 }}>
                  Total
                </p>

                <p style={{ margin: "0px", fontSize: "12px" }}>
                  {ticketCount?.statusCount?.Progress}
                </p>
              </div>
            </Grid>

            <Grid
              item
              lg={1.9}
              md={3.9}
              sm={5.9}
              xs={12}
              style={{
                background: "#FFFFFF",
                border: "1px solid rgb(0,0,0,0.1)",
                borderRadius: "5px",
                boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
                marginBottom: "5px",
                cursor: "pointer",
                // marginLeft: "10px",
              }}
              onClick={() => {
                setHead("Completed");
                setTicketStatus(3);
                localStorage.setItem("TableView", "Completed");
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #C2C2C2",
                  padding: "0px 5px",
                }}
              >
                <p style={styles.tablehdr}>Completed</p>
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    alignItems: "center",
                  }}
                >
                  <CompletedIcon />
                  <RightArrowIcon />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "10px",
                }}
              >
                <p style={{ margin: "0px", fontSize: "12px", fontWeight: 500 }}>
                  Total
                </p>

                <p style={{ margin: "0px", fontSize: "12px" }}>
                  {ticketCount?.statusCount?.Completed}
                </p>
              </div>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={0}
            lg={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <Grid item md={10} xs={12} sm={10} lg={10}>
              <p style={{ margin: "0px", fontSize: "16px", fontWeight: 600 }}>
                {head}
                <span
                  style={{
                    paddingLeft: "5px",
                    fontSize: "16px",
                    fontWeight: 600,
                  }}
                >
                  List
                </span>
              </p>
            </Grid>
            {head === "All" && <All />}
            {head === "New" && <Newtable />}
            {head === "Progress" && <Progress />}
            {head === "Completed" && <Completed />}
          </Grid>
        </>
      </div>
    </div>
  );
}
const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
  activehdr: {
    color: "#0071BC",
    fontWeight: 600,
    fontSize: "20px",
    margin: "0px",
    padding: "0px",
    // borderBottom: "1px solid #0071BC",
  },
  unactivehdr: {
    color: "#0071BC",
    fontWeight: 600,
    fontSize: "16px",
    margin: "0px",
    padding: "0px",
  },
  tablehdr: {
    margin: "0px",
    fontSize: "14px",
  },
};
