import React from "react";
import { Box, Button, Rating, Tooltip } from "@mui/material";
//  import Notes from '../../screens/ticketview/Notes';
import { Grid } from "@mui/material";
import { Avatar, makeStyles } from "@material-ui/core";
import { useState, useRef } from "react";
import { Nudge, NudgeRed, TicketIdIcon, ViewIcon } from "../../Assets/Icon";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import TaskAltIcon from "@mui/icons-material/TaskAlt";
import moment from "moment";
import JoditEditor from "jodit-react";
import StarIcon from "@mui/icons-material/Star";
import {
  TimelineConnector,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@material-ui/lab";
import { useEffect } from "react";
import ReOpen from "./ReOpen";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

export default function ViewTicket() {
  const AppCode = global.$userData.logindata.AppCode;
  const AccountsId = global.$userData.logindata.AccountsId;
  const ContactId = global.$userData.logindata.ContactId;
  const location = useLocation();
  const ticketdata = location.state.data;
  console.log(ticketdata, "ticketdata");
  const Navigate = useNavigate();

  //Reopen
  const [ReopenModal, setReopenModal] = useState(false);

  /* Jodit Editor*/
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const plainString = content.replace(/<[^>]+>/g, "");
  const [ShowDescription, setShowDescription] = useState([]);
  console.log("ShowDescription", ShowDescription);

  //Timeline
  const useStyles = makeStyles({
    timeline: {
      transform: "rotate(-90deg)",
    },
    timelineContentContainer: {
      textAlign: "left",

      margin: "0px",
    },
    timelineContent: {
      display: "inline-block",
      transform: "rotate(90deg)",
      textAlign: "center",
      background: "yellow",
      minWidth: 50,
    },
    timelineIcon: {
      transform: "rotate(-90deg)",
    },
  });
  const classes = useStyles();

  //FeedBack..
  const [rating, setRating] = useState(3);
  const [hover, setHover] = useState(-1);
  const [Comment, setComment] = useState("");
  const valuedate = new Date();
  const [nudge, setnudge] = useState(
    ticketdata.nudgeTime
      ? new Date(ticketdata.nudgeTime).getDate() == valuedate.getDate()
        ? false
        : true
      : true
  );

  //Post Feedback
  const FeedbackPost = async () => {
    const response = await global.$axios
      .post(`/ticket/addFeedback`, {
        AppCode: AppCode,
        Score: rating,
        Description: Comment,
        TicketId: ticketdata?.TicketId,
      })
      .then(async (response) => {
        if (response.data.Status === 200) {
          toast.success("Thank You For Your Valuable Feedback");
          setTimeout(() => {
            Navigate(-1);
          }, 2000);
        } else {
          toast.error("Something Went Wrong");
        }
      })
      .catch((e) => {
        console.error("Feedback Post Issue", e);
      });
  };

  const [TimeList, setTimeList] = useState({
    New: { Time: null, visible: true },
    Progress: { Time: null, visible: true },
    Hold: { Time: null, visible: false },
    Completed: { Time: null, visible: true },
    Solved: { Time: null, visible: false },
  });
  //Get Time
  const GetTime = async () => {
    const response = await global.$axios
      .get(`/ticket/statustime/${AppCode}/${ticketdata.TicketId}`)
      .then(async (response) => {
        if (response.data.Status === 200) {
          var apidata = response.data.Message;

          // Latest Progress time fetching
          const progressList = apidata.filter((x) => x.ToStatusId == 2);
          let progressTime = null;
          if (progressList.length > 0) {
            progressTime = progressList[0].StatusToTime;
          }
          // Latest Hold time fetching
          const HoldList = apidata.filter((x) => x.ToStatusId == 3);
          let HoldTime = null;
          if (HoldList.length > 0) {
            HoldTime = HoldList[0].StatusToTime;
          }
          // Latest Completed time fetching
          const CompletedList = apidata.filter((x) => x.ToStatusId == 4);
          let completedTime = null;
          if (CompletedList.length > 0) {
            completedTime = CompletedList[0].StatusToTime;
          }

          // Latest Completed time fetching
          const SolvedList = apidata.filter((x) => x.ToStatusId == 4);
          let solvedTime = null;
          if (SolvedList.length > 0) {
            solvedTime = SolvedList[0].StatusToTime;
          }

          setTimeList({
            New: { Time: ticketdata.CreatedAt, visible: true },
            Progress: { Time: progressTime, visible: true },
            Hold: { Time: HoldTime, visible: false },
            Completed: { Time: completedTime, visible: true },
            Solved: { Time: solvedTime, visible: false },
          });

          // setStatusTime(response.data.Message);
        } else {
          toast.error("Something Went Wrong");
        }
      })
      .catch((e) => {
        console.error("Get Time Issue", e);
      });
  };

  // Description Update
  const DescriptionList = async () => {
    const response = await global.$axios.post("/ticket/descriptionupdate", {
      AppCode: AppCode,
      For: "9",
      ForId: ticketdata?.TicketId,
      Description: content,
      UpdatedType: "9",
      CreatedBy: ContactId,
      TicketStatus: ticketdata.TicketStatus,
    });

    if (response.data.Status == "200") {
      toast.success("Update Sent Successfully");
      setTimeout(() => {
        Navigate(-1);
      }, 1500);
    } else {
      toast.error("Something Went Wrong");
    }
  };

  // Get Updates
  const GetUpdate = async () => {
    const response = await global.$axios.put(`/ticket/updatestatus`, {
      AppCode: AppCode,
      ForId: ticketdata?.TicketId,
      TicketStatus: ticketdata?.TicketStatus,
    });
    setShowDescription(response.data.Message);
  };
  //nudgeticket

  const nudgetic = async () => {
    const response = await global.$axios
      .post("/ticket/nudgeUpdate", {
        TicketId: ticketdata?.TicketId,
        AppCode: AppCode,
      })
      .then(async (response) => {
        // console.log(response.data);
        if (response.data.status == 200) {
          // console.log(response.data.message);
          if (response.data.message == "0") {
            setnudge(true);
          } else {
            setnudge(false);
          }
        } else {
          setnudge(true);
        }
      })
      .catch((e) => {
        console.error("ticketview", e);
      });
  };
  // nudgeTime
  useEffect(() => {
    GetUpdate();
    GetTime();
    // nudgetic()
  }, [nudge]);

  return (
    <div>
      <Grid
        container
        spacing={0}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
        }}
      >
        <Grid item lg={3} xs={12} style={{ margin: "0px 0px" }}>
          <span
            onClick={() => Navigate(-1)}
            style={{
              color: "#0071BC",
              cursor: "pointer",
            }}
          >
            <Tooltip title="Back">
              <ArrowBackIosNewIcon
                sx={{
                  width: "20px",
                  height: "15px",
                  paddingTop: "2px",
                }}
              />
            </Tooltip>
          </span>
          <span
            onClick={() => Navigate(-1)}
            style={{
              color: "#0071BC",
              fontSize: "16px",
              fontWeight: 600,
              cursor: "pointer",
            }}
          >
            Ticket Status /{" "}
          </span>
          <span style={styles.activehdr}>Ticket View</span>
        </Grid>
      </Grid>
      <Grid
        container
        xs={12}
        style={{
          margin: "0px",
          background:
            "linear-gradient(90deg, #C4C4C4 1.08%, rgba(231, 231, 231, 0.411458) 75.27%, rgba(245, 245, 245, 0.177083) 99.99%, rgba(255, 255, 255, 0) 100%)",
          borderTopLeftRadius: "20px",
          borderBottomLeftRadius: "20px",
          padding: "5px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Grid item xs={10}>
          <TicketIdIcon />
          <span
            style={{ fontSize: "14px", fontWeight: 600, paddingLeft: "10px" }}
          >
            Ticket ID
          </span>
          <span
            style={{
              paddingLeft: "15px",
              fontSize: "14px",
              fontWeight: 500,
              color: "red",
            }}
          >
            {ticketdata.TicketId}
          </span>
          <span
            style={{
              paddingLeft: "15px",
              fontSize: "14px",
              fontWeight: 500,
              color: "#0071BC",
            }}
          >
            {ticketdata?.created?.UserFirstName}{" "}
            {ticketdata?.created?.UserLastName}
          </span>
        </Grid>
        {ticketdata.TicketStatus === 4 && (
          <Grid
            item
            md={2}
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "5px 0px",
            }}
            onClick={() => {
              setReopenModal(true);
            }}
          >
            <Button
              size="small"
              variant="contained"
              sx={{ minHeight: "22px", maxHeight: "22px", padding: "12px" }}
            >
              REOPEN
            </Button>
          </Grid>
        )}
      </Grid>

      <Grid
        xs={12}
        container
        style={{
          margin: "10px 0px 5px 0px",
          border: "1px solid rgba(0,0,0,0.04)",
          padding: "0px 5px 5px 5px",
          borderRadius: "15px",
          boxShadow: "3px 5px 3px rgb(0,0,0,0.2)",
          fontSize: "14px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {/*New TimeLine*/}
        <Grid
          xs={10}
          container
          item
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid
            item
            style={{
              margin: "0px 10px 0px 10px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <TimelineSeparator>
              {ticketdata.TicketStatus >= 1 ? (
                <TaskAltIcon
                  fontSize="small"
                  sx={{ margin: "4px 0px 3px 0px", color: "#0071BC" }}
                />
              ) : (
                <TimelineDot />
              )}
            </TimelineSeparator>
            <p style={{ margin: "0px" }}>New</p>{" "}
            <span>
              {moment(TimeList.New.Time, "YYYY-MM-DD hh:mm A").format(
                "DD-MM-YYYY hh:mm A"
              )}
            </span>
          </Grid>

          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <TimelineItem className={classes.timeline}>
              <TimelineConnector
                style={{
                  background:
                    ticketdata.TicketStatus >= 2
                      ? "#0071BC"
                      : "rgba(0,0,0,0.3)",
                }}
              />
            </TimelineItem>
          </Grid>

          <Grid
            item
            style={{
              margin: "0px 10px 0px 10px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <TimelineSeparator>
              {ticketdata.TicketStatus >= 2 ? (
                <TaskAltIcon
                  fontSize="small"
                  sx={{ margin: "4px 0px 3px 0px", color: "#0071BC" }}
                />
              ) : (
                <TimelineDot />
              )}
            </TimelineSeparator>
            <p style={{ margin: "0px" }}>Progress</p>{" "}
            {TimeList.Progress.Time != null && (
              <span>
                {moment(TimeList.Progress.Time, "YYYY-MM-DD hh:mm A").format(
                  "DD-MM-YYYY hh:mm A"
                )}
              </span>
            )}
          </Grid>

          {/* {ticketdata.TicketStatus == 3 && (
                        <>
                            <Grid
                                item
                                sx={{
                                    margin: "3px 0px 0px 0px",
                                }}
                            >
                                <TimelineItem className={classes.timeline}>
                                    <TimelineConnector
                                        style={{
                                            background:
                                                ticketdata.TicketStatus >= 3
                                                    ? "#0071BC"
                                                    : "rgba(0,0,0,0.3)",
                                        }}
                                    />
                                </TimelineItem>
                            </Grid>

                            <Grid
                                item
                                style={{
                                    margin: "0px 10px 0px 10px",
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "column",
                                }}
                            >
                                <TimelineSeparator>
                                    {ticketdata.TicketStatus >= 3 ? (
                                        <TaskAltIcon
                                            fontSize="small"
                                            sx={{ margin: "4px 0px 3px 0px", color: "#0071BC" }}
                                        />
                                    ) : (
                                        <TimelineDot />
                                    )}
                                </TimelineSeparator>
                                <p style={{ margin: "0px" }}>Hold</p>{" "}
                                {TimeList.Hold.Time != null && (
                                    <span>
                                        {moment(TimeList.Hold.Time, "YYYY-MM-DD hh:mm A").format(
                                            "DD-MM-YYYY hh:mm A"
                                        )}
                                    </span>
                                )}
                            </Grid>
                        </>
                    )} */}

          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <TimelineItem className={classes.timeline}>
              <TimelineConnector
                style={{
                  background:
                    ticketdata.TicketStatus >= 4
                      ? "#0071BC"
                      : "rgba(0,0,0,0.3)",
                }}
              />
            </TimelineItem>
          </Grid>

          <Grid
            item
            style={{
              margin: "0px 10px 0px 10px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <TimelineSeparator>
              {ticketdata.TicketStatus >= 4 ? (
                <TaskAltIcon
                  fontSize="small"
                  sx={{ margin: "4px 0px 3px 0px", color: "#0071BC" }}
                />
              ) : (
                <TimelineDot />
              )}
            </TimelineSeparator>
            <p style={{ margin: "0px" }}>Completed</p>{" "}
            <span>
              {ticketdata?.TicketClosedTime &&
                moment(
                  ticketdata?.TicketClosedTime,
                  "YYYY-MM-DD hh:mm A"
                ).format("DD-MM-YYYY hh:mm A")}
            </span>
          </Grid>

          {/* <Grid
                        item
                        sx={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <TimelineItem className={classes.timeline}>
                            <TimelineConnector
                                style={{
                                    background:
                                        ticketdata.TicketStatus >= 5
                                            ? "#0071BC"
                                            : "rgba(0,0,0,0.3)",
                                }}
                            />
                        </TimelineItem>
                    </Grid> */}

          {/* <Grid
                        item
                        style={{
                            margin: "0px 10px 0px 10px",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                        }}
                    >
                        <TimelineSeparator>
                            {ticketdata.TicketStatus >= 5 ? (
                                <TaskAltIcon
                                    fontSize="small"
                                    sx={{ margin: "4px 0px 3px 0px", color: "#0071BC" }}
                                />
                            ) : (
                                <TimelineDot />
                            )}
                        </TimelineSeparator>
                        <p style={{ margin: "0px" }}>Solved</p>{" "}
                        <span>
                            {ticketdata?.TicketSolvedTime &&
                                moment(
                                    ticketdata?.TicketSolvedTime,
                                    "YYYY-MM-DD hh:mm A"
                                ).format("DD-MM-YYYY hh:mm A")}
                        </span>
                    </Grid> */}
        </Grid>
        {ticketdata?.TicketStatus < 3 && (
          <Grid
            item
            xs={2}
            style={{
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            {nudge ? (
              <Tooltip title="Nudge" onClick={() => nudgetic()}>
                <Nudge />
              </Tooltip>
            ) : (
              <Tooltip title="Already Nudged">
                <NudgeRed />
              </Tooltip>
            )}
          </Grid>
        )}
      </Grid>

      {/*List*/}
      <Grid xs={12} container>
        <Grid
          container
          item
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
          md={7}
          xs={12}
        >
          <Grid
            item
            style={{ padding: "3px", lineHeight: "27px" }}
            md={6.2}
            xs={12}
          >
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p style={styles.hdr}>Contact Name</p>
              <p style={styles.txt}>
                {ticketdata?.contact?.CreatedBy?.slice(0, 20)}
              </p>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p style={styles.hdr}>Department</p>
              <p style={styles.txt}>{ticketdata?.dept?.DepartmentName}</p>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p style={styles.hdr}>Product</p>
              <p style={styles.txt}>{ticketdata?.product?.ProductName}</p>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p style={styles.hdr}>Issue</p>
              <p style={styles.txt}>{ticketdata?.issue?.IssueName}</p>
            </Grid>
          </Grid>

          {/*List RHS*/}

          <Grid
            item
            style={{ padding: "3px", lineHeight: "27px" }}
            md={5.6}
            xs={12}
          >
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p style={styles.hdr}>Email</p>
              <p style={styles.txt}>
                {ticketdata?.contact?.EmailId
                  ? ticketdata?.contact?.EmailId
                  : "-"}
              </p>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p style={styles.hdr}>Created On</p>
              <p style={styles.txt}>
                {moment(ticketdata.CreatedAt, "YYYY-MM-DD hh:mm A").format(
                  "DD-MM-YYYY hh:mm A"
                )}
              </p>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p style={styles.hdr}>View Attachment</p>
              <p style={styles.txt}>
                {ticketdata?.AttachmentFile === ""
                  ? "-"
                  : ticketdata.AttachmentFile.split(",").map(
                      (Attachement, index) => {
                        return (
                          <>
                            <Tooltip
                              style={{ paddingRight: "5px" }}
                              title={
                                <div
                                  style={{ height: "102px", width: "180px" }}
                                >
                                  <img
                                    src={Attachement}
                                    style={{
                                      width: "180px",
                                      position: "absolute",
                                      rigth: "80%",
                                    }}
                                  />
                                </div>
                              }
                            >
                              <Link to={Attachement} target="blank">
                                <ViewIcon
                                  style={{ width: "20px", height: "20px" }}
                                />
                              </Link>
                            </Tooltip>
                          </>
                        );
                      }
                    )}
              </p>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "flex-start",
              margin: "5px 0px 0px 0px",
            }}
          >
            <span
              style={{
                margin: "0px",
                padding: "0px 20px 0px 0px",
                fontWeight: "700",
                fontSize: "14px",
              }}
            >
              Description
            </span>
            <Grid
              item
              xs={12}
              style={{
                background: "#FFF",
                padding: "5px",
                maxHeight: "30vh",
                boxShadow: "3px 5px 3px rgb(0,0,0,0.2)",
                overflow: "scroll",
                borderRadius: "5px",
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: ticketdata.TicketDescription,
                }}
              />
            </Grid>
          </Grid>

          {ticketdata.TicketStatus <= 4 && (
            <Grid container>
              {ticketdata.TicketStatus == 4 ? (
                <Grid
                  // container
                  item
                  xs={12}
                  style={{
                    margin: "10px 0px 0px 0px",
                    border: "1px solid rgba(0,0,0,0.06)",
                  }}
                >
                  <p
                    style={{
                      padding: "10px 15px",
                      background: "rgba(0, 113, 188, 0.1)",
                      margin: "0px",
                      fontWeight: 500,
                    }}
                  >
                    Feedback
                  </p>
                  <Grid
                    item
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ margin: "10px 0px 5px 0px", fontSize: "14px" }}>
                      Rate Us
                    </p>
                  </Grid>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Rating
                      name="hover-feedback"
                      value={rating}
                      precision={1}
                      onChange={(event, newValue) => {
                        setRating(newValue);
                      }}
                      // onChangeActive={(event, newHover) => {
                      //   setHover(newHover);
                      // }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {/* {rating !== null && (
                      <Box sx={{ ml: 2 }}>
                        {labels[hover !== -1 ? hover : rating]}
                      </Box>
                    )} */}
                  </Box>
                  <Box sx={{ margin: "0px 10px" }}>
                    <span
                      style={{ margin: "10px 0px 5px 0px", fontSize: "14px" }}
                    >
                      Share More About Your Experience
                    </span>
                    <textarea
                      value={Comment}
                      onChange={(e) => setComment(e.target.value)}
                      placeholder="Start Here ..."
                      style={{
                        width: "100%",
                        border: "1px solid gray",
                        background: "#f5f5f5",
                        borderRadius: "10px",
                        padding: "10px",
                        fontSize: "18px",
                        fontWeight: "200",
                        height: "80px",
                        outline: "none",
                      }}
                    ></textarea>
                  </Box>
                </Grid>
              ) : (
                <Grid item xs={12} style={{ margin: "10px 0px 0px 0px" }}>
                  <p
                    style={{
                      padding: "10px 15px",
                      background: "rgba(0, 113, 188, 0.1)",
                      margin: "0px 0px 0px 0px",
                      fontWeight: 500,
                    }}
                  >
                    Description
                  </p>
                  <JoditEditor
                    ref={editor}
                    value={content}
                    onBlur={(e) => setContent(e)}
                  />
                </Grid>
              )}
            </Grid>
          )}
        </Grid>

        <Grid
          container
          item
          style={{ padding: "10px 5px 0px 15px" }}
          md={5}
          xs={12}
        >
          <Grid
            item
            className="Main"
            xs={12}
            style={{
              border: "1px solid rgba(0,0,0,0.05",
              boxShadow: "3px 5px 3px rgb(0,0,0,0.2)",
              padding: "10px 5px",
              borderRadius: "10px",
            }}
          >
            <span
              style={{
                fontWeight: 600,
                fontSize: "16px",
                margin: "5px 0px",
                color: "#0071BC",
              }}
            >
              Discussion
            </span>
            <Grid
              className="Main"
              sx={{
                maxHeight: "68vh",
                overflow: "scroll",
              }}
              container
              item
            >
              {ShowDescription?.map((item) => {
                if (item?.For == 8) {
                  return (
                    <Grid
                      xs={12}
                      item
                      style={{
                        padding: "10px",
                        borderBottom: "1px solid rgba(0,0,0,0.04)",

                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Grid
                        item
                        xs={11.5}
                        style={{
                          border: "1px solid rgba(0,0,0,0.1)",
                          padding: "2px",
                        }}
                      >
                        {" "}
                        <Grid
                          container
                          item
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "2px 5px",
                            background:
                              item.For == 8
                                ? "rgba(47,207,47,0.1)"
                                : "rgba(0,0,0,0.1)",
                          }}
                        >
                          <Grid item>
                            <p
                              style={{
                                margin: "0px",
                                // color:item.For == 9 ?'#232323' : "#0071BC",
                                fontSize: "12px",
                              }}
                            >
                              <span style={{ paddingRight: "5px" }}>
                                {item.For == 8
                                  ? item?.contact?.FirstName.substr(0, 20)
                                  : ticketdata?.updated?.UserFirstName}
                              </span>
                              {moment(
                                item.CreatedAt,
                                "YYYY-MM-DD hh:mm A"
                              ).format("DD-MM-YYYY hh:mm A")}
                            </p>
                          </Grid>
                          <Grid item>
                            <p
                              style={{
                                margin: "0px 0px 0px 0px",
                                fontSize: "12px",
                              }}
                            >
                              {item?.InternalStatusId === 1
                                ? "New"
                                : item?.InternalStatusId === 2
                                ? "Progress"
                                : item?.InternalStatusId === 3
                                ? "Progress"
                                : item?.InternalStatusId === 4
                                ? "Completed"
                                : "Solved"}
                            </p>
                          </Grid>
                        </Grid>
                        <div
                          style={{
                            display: "flex",
                            margin: "5px 0px 0px 0px",
                            alignItems: "center",
                            gap: "5px",
                            padding: "5px",
                          }}
                        >
                          <Avatar
                            alt={
                              item?.contact
                                ? item?.contact?.FirstName
                                : item?.create?.CreatedBy
                            }
                            src="/static/images/avatar/2.jpg"
                          />
                          <div
                            style={{ fontSize: "12px" }}
                            dangerouslySetInnerHTML={{
                              __html: item?.predefinereply,
                            }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            margin: "0px 0px 0px 5px",
                          }}
                        >
                          <span style={{ fontSize: "12px", fontWeight: 500 }}>
                            Comments :
                          </span>
                          <div
                            style={{ fontSize: "12px" }}
                            dangerouslySetInnerHTML={{
                              __html: item?.Description,
                            }}
                          />
                        </div>
                        {item.json && (
                          <span
                            style={{
                              fontSize: "12px",
                              paddingRight: "3px",
                              fontWeight: 500,
                            }}
                          >
                            Attachments -
                          </span>
                        )}
                        {item.json &&
                          item.json.split(",").map((Attachment) => {
                            return (
                              <>
                                <Tooltip
                                  style={{ paddingRight: "5px" }}
                                  title={
                                    <div
                                      style={{
                                        height: "102px",
                                        width: "180px",
                                      }}
                                    >
                                      <img
                                        src={Attachment}
                                        style={{
                                          width: "180px",
                                          position: "absolute",
                                          rigth: "80%",
                                        }}
                                      />
                                    </div>
                                  }
                                >
                                  <Link to={Attachment} target="blank">
                                    <ViewIcon
                                      style={{
                                        width: "15px",
                                        height: "15px",
                                        paddingTop: "5px",
                                      }}
                                    />
                                  </Link>
                                </Tooltip>
                              </>
                            );
                          })}
                      </Grid>
                    </Grid>
                  );
                } else if (item?.For == 7 || item?.For == 9) {
                  return (
                    <Grid
                      xs={12}
                      item
                      style={{
                        margin: "0px",
                        display: "flex",
                        padding: "10px",
                        borderBottom: "1px solid rgba(0,0,0,0.04)",
                        // background: "red"
                      }}
                    >
                      {" "}
                      <Grid
                        item
                        xs={11.5}
                        style={{
                          border: "1px solid rgba(0,0,0,0.1)",
                          padding: "2px",
                        }}
                      >
                        {" "}
                        <Grid
                          container
                          item
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            background: "#0071BC1A",
                            padding: "2px 5px",
                          }}
                        >
                          <Grid item>
                            <p
                              style={{
                                margin: "0px",
                                fontSize: "12px",
                                color: "#0071BC",
                              }}
                            >
                              {item?.InternalStatusId === 1
                                ? "New"
                                : item?.InternalStatusId === 2
                                ? "Progress"
                                : item?.InternalStatusId === 3
                                ? "Progress"
                                : item?.InternalStatusId === 4
                                ? "Completed"
                                : "Solved"}
                            </p>
                          </Grid>
                          <Grid item>
                            <p
                              style={{
                                margin: "0px",
                                color: "#0071BC",
                                fontSize: "12px",
                                textAlign: "end",
                              }}
                            >
                              <span style={{ paddingRight: "5px" }}>
                                {ticketdata?.updated?.UserFirstName
                                  ? ticketdata?.updated?.UserFirstName.substr(
                                      0,
                                      20
                                    )
                                  : ""}
                              </span>
                              {moment(
                                item.CreatedAt,
                                "YYYY-MM-DD hh:mm A"
                              ).format("DD-MM-YYYY hh:mm A")}
                            </p>
                          </Grid>
                        </Grid>
                        <div
                          style={{
                            display: "flex",
                            margin: "5px 0px 0px 0px",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            padding: "5px",
                          }}
                        >
                          <div
                            style={{ fontSize: "12px", paddingRight: "5px" }}
                            dangerouslySetInnerHTML={{
                              __html: item?.Description,
                            }}
                          />

                          <Avatar
                            alt={ticketdata?.updated?.UserFirstName}
                            src="/static/images/avatar/2.jpg"
                            style={{ marginLeft: "5px" }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  );
                }
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "30px 0px 0px 0px",
          padding: "20px 0px 0px 0px",
          borderTop: "1px solid rgba(0,0,0,0.06)",
        }}
      >
        {ticketdata.TicketStatus < 5 && (
          <>
            {ticketdata.TicketStatus == 4 ? (
              <Button
                variant="contained"
                sx={{
                  minHeight: "22px",
                  maxHeight: "22px",
                  padding: "12px",
                  textTransform: "capitalize",
                }}
                onClick={() => FeedbackPost()}
              >
                Send
              </Button>
            ) : (
              <Button
                variant="contained"
                sx={{
                  minHeight: "22px",
                  maxHeight: "22px",
                  padding: "12px",
                  textTransform: "capitalize",
                }}
                onClick={() => {
                  DescriptionList();
                }}
              >
                Submit
              </Button>
            )}
          </>
        )}
      </Grid>

      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
      {ReopenModal && (
        <ReOpen
          ReopenModal={ReopenModal}
          setReopenModal={setReopenModal}
          TicketId={ticketdata.TicketId}
        />
      )}
    </div>
  );
}

const styles = {
  SearchInput: {
    background: "transparent",
    outline: "none",
    border: "none",
    borderRadius: "20px",
    height: "15px",
  },
  activehdr: {
    color: "#0071BC",
    fontWeight: 600,
    fontSize: "20px",
    margin: "0px",
    padding: "0px",
  },
  unactivehdr: {
    color: "#0071BC",
    fontWeight: 600,
    fontSize: "16px",
    margin: "0px",
    padding: "0px",
  },
  tablehdr: {
    margin: "0px",
    fontSize: "14px",
  },
  img: {
    margin: 10,
  },
  txt: {
    fontSize: "12px",
    margin: "0px",
  },

  hdr: {
    fontSize: "14px",
    margin: "0px",
    fontWeight: 700,
  },
  txt1: {
    fontSize: "14px",
    margin: "0px",
    fontWeight: 800,
    color: "#0071BC",
  },
};
