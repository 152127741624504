import * as React from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Batman, CustomerActiveIcon, CustomerIcon, TeamTicketActive, Teamticket } from "../Assets/Icon"
import CircleIcon from '@mui/icons-material/Circle';
import LogoutIcon from '@mui/icons-material/Logout';

import {
  AppBar,
  Drawer,
  Box,
  CssBaseline,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Stack,
  Toolbar,
  Typography,
  Avatar,
  Menu,
  MenuItem,
  Grid,
} from "@mui/material";


import {
  TreeOne,
  DashBoardIcon,
  DashBoardActive,
  TicketView,
  NewTicket,
  NewTicketActive,
  TicketViewActive
} from "../Assets/Icon";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import Content from "./Content";
import { useDispatch } from "react-redux";
import { TreeItem, TreeView } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core";
import PersonIcon from '@mui/icons-material/Person';
const drawerWidth = 205;
function MainLayout(props, { setTab, tab }) {
  const userdata = global.$userData.logindata.FirstName
  // console.log(userdata, "userdata");

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const Navigate = useNavigate();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const open = Boolean(anchorElUser);

  const [activeLink, setActiveLink] = React.useState(null);
  const [expanded, setExpanded] = React.useState([]);

  const handleChange = (event, nodes) => {
    setExpanded(nodes);
  };

  const logout = () => {
    dispatch({ type: "SET_USER_OUT", payload: false });
    Navigate("/auth");
  };

  // const useStyles = makeStyles({
  //   root: {
  //     flexGrow: 1,
  //   },
  //   label: {
  //     color: "#FFF",
  //     background: "transparent",
  //   },
  // });

  const useTreeItemStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    content: {
      flexDirection: "row-reverse",
    },
    labelRoot: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
      marginRight: theme.spacing(0),
    },
    labelText: {
      fontWeight: "inherit",
      flexGrow: 1,
      color: "#FFF",
      paddingLeft: "5px",
    },
  }));

  function StyledTreeItem(props) {
    const classes = useTreeItemStyles();
    const { labelText, labelIcon: LabelIcon, ...other } = props;
    return (
      <TreeItem
        label={
          <div className={classes.labelRoot}>
            <LabelIcon color="inherit" className={classes.labelIcon} />
            <Typography variant="body2" className={classes.labelText}>
              {labelText}
            </Typography>
          </div>
        }
        classes={{
          content: classes.content,
        }}
        {...other}
      />
    );
  }
  const classes = useTreeItemStyles();

  const drawer = (
    <div>
      <Stack
        sx={{
          width: `${drawerWidth - 1}px`,
          alignItems: "center",
          background: "#f5f5f5",
          paddingBottom: "5px",
        }}
      >
        <TreeOne style={{ width: "55%", padding: 2 }} />
      </Stack>

      <Box
        style={{
          background: "linear-gradient(350deg,  #FFFFFF, 10%, #2D71BC)",
          height: "calc(105vh - 100px)",
          maxHeight: "calc(105vh - 100px)",
          // marginTop: "4px",
          // display: "flex",
          // alignItems: "center",
          // justifyContent: "center",
          // width: `${drawerWidth - 1}px`,
          // height: "89.5vh",


        }}
      >
        <List>
          <ListItem
            disablePadding
            onClick={() => {
              navigate("CreateTicket");
            }}
          >
            <ListItemButton
              onClick={() => setActiveLink("CreateTicket")}
              style={
                activeLink === "CreateTicket" ? styles.touchbtn : styles.untouchbtn
              }
              align={"center"}
            >
              <ListItemIcon style={{ marginLeft: 2 }}>
                {activeLink === "CreateTicket" || activeLink === "app" ? (
                  <TicketViewActive width={"50px"}
                  />
                ) : (
                  <NewTicket />
                )}
              </ListItemIcon>
              New Ticket
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            onClick={() => {
              navigate("Ticketstatus");
            }}
          >
            <ListItemButton
              onClick={() => setActiveLink("ViewTicket")}
              style={
                activeLink === "ViewTicket" ? styles.touchbtn : styles.untouchbtn
              }
              align={"center"}
            >
              <ListItemIcon style={{ marginLeft: 2 }}>
                {activeLink === "ViewTicket" || activeLink === "app" ? (
                  <NewTicketActive />
                ) : (
                  <TicketView />
                )}
              </ListItemIcon>
              Ticket Status
            </ListItemButton>
          </ListItem>

          {global?.$userData?.logindata?.Designation === "1" ||
            global?.$userData?.logindata?.Designation === "2" ||
            global?.$userData?.logindata?.Designation === "3" ||
            global?.$userData?.logindata?.Designation === "4" ?
            <ListItem
              disablePadding
              onClick={() => {
                navigate("TeamTicket");
              }}
            >
              <ListItemButton
                onClick={() => setActiveLink("TeamTicket")}
                style={
                  activeLink === "TeamTicket" ? styles.touchbtn : styles.untouchbtn
                }
                align={"center"}
              >
                <ListItemIcon style={{ marginLeft: 2 }}>
                  {activeLink === "TeamTicket" || activeLink === "app" ? (
                    <TeamTicketActive width={"50px"} />
                  ) : (
                    <Teamticket />
                  )}
                </ListItemIcon>
                Team Ticket
              </ListItemButton>
            </ListItem> : ""}
          <ListItem
            disablePadding
            onClick={() => {
              navigate("Profile");
            }}
          >
            <ListItemButton
              onClick={() => setActiveLink("Profile")}
              style={
                activeLink === "Profile" ? styles.touchbtn : styles.untouchbtn
              }
              align={"center"}
            >
              <ListItemIcon style={{ marginLeft: 2 }}>
                {activeLink === "Profile" || activeLink === "app" ? (
                  <CustomerActiveIcon width={"35px"} height={"30px"} />
                ) : (
                  <CustomerIcon width={"30px"} height={"30px"} />
                )}
              </ListItemIcon>
              Profile
            </ListItemButton>
          </ListItem>

        </List>
      </Box>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          background: "#F5F5F5",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" }, color: "#000" }}
          >
            <MenuIcon />
          </IconButton>

          <Box item sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>

              <Grid
                item
                xs={12}
                style={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <NotificationsNoneIcon
                  sx={{
                    color: "#000",
                    marginRight: "20px",
                    alignSelf: "center",
                  }}
                  fontSize="large"
                />
                <div
                  style={{
                    textAlign: "end",
                    marginRight: 15,
                    alignSelf: "center",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "#000", alignSelf: "center" }}
                  >
                    {global.$userData.logindata.FirstName}
                  </Typography>
                  <Typography
                    variant="subtitle2  "
                    style={{ color: "#000", alignSelf: "center" }}
                  >
                    Good Morning
                  </Typography>
                </div>

                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="TabTree" src={global?.$userData?.logindata?.ImagePathLink ? global?.$userData?.logindata?.ImagePathLink : "/static/images/avatar/2.jpg"} />
                </IconButton>

                <Menu
                  id="account-menu"
                  open={open}
                  anchorEl={anchorElUser}
                  // id="account-menu"
                  // open={open}
                  onClose={handleCloseUserMenu}
                  onClick={handleCloseUserMenu}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        // width: 32,
                        // height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        // width: 10,
                        // height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                      '.css-108ggty-MuiPaper-root-MuiMenu-paper-MuiPopover-paper': {
                        minWidth: 100
                      }

                    },

                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <Grid container item xs={12} style={{ width: "20vw", height: "20wh" }}>
                    <Grid item xs={12} style={{
                      display: 'flex',
                      justifyContent: "center",
                      marginTop: '10px'
                    }}>
                      <div>
                        <Avatar
                          sx={{ width: 60, height: 60 }}
                        >{global?.$userData?.logindata?.ImagePathLink ? <img src={global?.$userData?.logindata?.ImagePathLink}
                          style={{ maxWidth: 180, maxHeight: 200, borderRadius: "30px", }}
                          alt="Thumb" /> : <Batman />}

                        </Avatar>
                      </div>
                    </Grid>
                    <Grid item xs={12} style={{
                      display: "flex",
                      justifyContent: "center"
                    }} >
                      <span style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        marginTop: '10px',
                        alignSelf: "center",
                        color: "#2D71BC",
                        textTransform: "capitalize"
                      }}>{userdata}</span>
                    </Grid>
                  </Grid>

                  {/* <MenuItem
                    onClick={() => {
                      Navigate("Profile")
                    }}
                  ><PersonIcon />
                    <span style={{ fontWeight: "500", marginLeft: '3px' }}>Profile</span>
                  </MenuItem> */}
                  <MenuItem
                    onClick={() => {
                      handleCloseUserMenu();
                      logout();
                    }}
                  ><LogoutIcon />
                    <span style={{ fontWeight: "500", marginLeft: '5px' }}>Logout</span>
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </Box>
        </Toolbar>
      </AppBar>

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              border: "unset",
              background: "#F5F5F5",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        component="main"
        className="Main"
        sx={{
          flexGrow: 1,
          p: 3,
          pb: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          height: "calc(113vh - 100px)",
          maxHeight: "calc(113vh - 100px)",
          overflow: "auto",

          // backgroundImage:
          //   "linear-gradient(-180deg, #FFF 0%, rgba(10, 124, 198, 0.2) 100%)",
          // background: "#273c62",
        }}
      >
        <Toolbar />
        <Content>
          <Outlet />
        </Content>
      </Box>
    </Box>
  );
}

export default MainLayout;

const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
  touchbtn: {
    color: "black",
    background: "#FFFFFF",
    fontWeight: 600,
    letterSpacing: "0.5px",
    padding: "5px",
    borderTopRightRadius: "180px",
    borderBottomRightRadius: "180px",
  },
  untouchbtn: {
    color: "#FFFFFF",
    padding: "10px",
  },
  checkboxLabel: {
    border: "1px solid black",
    fontWeight: "100",
    fontSize: "20px",
  },
};
