import { Box, Grid, Tooltip } from '@mui/material';
import { DataGrid, GridSearchIcon } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import moment from 'moment';

function All() {
    const AppCode = global.$userData.logindata.AppCode
    const ContactId = global.$userData.logindata.ContactId
    const Navigate = useNavigate();

    // console.log(AppCode, "AppCode");

    const [All, SetAll] = useState([])
    // console.log(New, "New");
    const [SearchAll, SetSearchAll] = useState([])

    //usestate
    const Alltable = async () => {
        const response = await global.$axios.get(`customerticket/CuscreateallTickets/${AppCode}/${ContactId}`)
        SetAll(response.data.Message)
        SetSearchAll(response.data.Message)
    }
    const [sortModel, setSortModel] = React.useState([
        {
            field: "TicketId",
            sort: "desc",
        },
    ]);
    const columns = [
        {
            field: "TicketId",
            headerClassName: "super-app-theme--header",
            headerName: "ID",
            width: 70,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "AttachmentFile" || "",
            headerName: "Action",
            width: 100,
            headerClassName: "super-app-theme--header",
            align: "center",
            headerAlign: "center",
            renderCell: (cellValues) => {
                // console.log(cellValues, "cellValues");
                return (
                    <Tooltip title="View Ticket">
                        <VisibilityOutlinedIcon
                            style={{ width: "20px", height: "20px" }}
                            onClick={() =>
                                Navigate("TicketView", {
                                    state: { data: cellValues.row },
                                })
                            }
                        />
                    </Tooltip>
                )
            },
        },


        {
            field: "DepartmentName",
            headerClassName: "super-app-theme--header",
            headerName: "Department",
            width: 190,
            valueGetter: (fields) => fields?.row?.dept?.DepartmentName,
            align: "center",
            headerAlign: "center",


        },
        {
            field: "product",
            headerClassName: "super-app-theme--header",
            headerName: "Product",
            width: 170,
            valueGetter: (fields) => fields?.row?.product?.ProductName,
            align: "center",
            headerAlign: "center",

        },
        {
            field: "IssueName",
            headerClassName: "super-app-theme--header",
            headerName: "Issue",
            width: 170,
            valueGetter: (fields) => fields?.row?.issue?.IssueName,
            align: "center",
            headerAlign: "center",

        },
        {
            field: "Status",
            headerClassName: "super-app-theme--header",
            headerName: "Status",
            width: 170,
            align: "center",
            headerAlign: "center",
        },



        {
            field: "CreatedAt",
            headerName: "Created At",
            width: 150,
            headerClassName: "super-app-theme--header",
            valueGetter: (fields) =>
                moment(fields?.row?.CreatedAt, "YYYY-MM-DD hh:mm").format(
                    "DD-MM-YY hh.mm A"
                ) || "",
            align: "center",
            headerAlign: "center",
        },
        {
            field: "ECAt",
            headerName: "ETC",
            width: 150,
            headerClassName: "super-app-theme--header",
            valueGetter: (fields) =>
                moment(fields?.row?.ECAt, "YYYY-MM-DD hh:mm").format(
                    "DD-MM-YY hh.mm A"
                ) || "",
            align: "center",
            headerAlign: "center",
        },
        {
            field: "TicketDescription",
            headerName: "Description",
            width: 200,
            headerClassName: "super-app-theme--header",
            valueGetter: (fields) => {
                var space = fields?.row?.TicketDescription.replace(/<[^>]+>/g, "");
                var format = space.replace(/\&nbsp;/g, "");
                return format || "";
            },
        },

    ];
    async function filterList(term) {
        let ticketData = [];
        if (term !== "") {
            ticketData = All.filter((item) => {
                const TicketId = String(item?.TicketId);
                const DepartmentName = String(item?.dept?.DepartmentName);
                const ProductName = String(item?.product?.ProductName);
                const IssueName = String(item?.issue?.IssueName);
                const PriorityLevel = String(item?.PriorityLevel);
                // const ETC = String(
                //     moment(item?.ETC, "YYYY-MM-DD hh:mm").format(
                //         "DD-MM-YYYY hh:mm A"
                //     )
                // );
                // const ClosedTime = String(
                //     moment(item?.TicketClosedTime, "YYYY-MM-DD hh:mm").format(
                //         "DD-MM-YYYY hh:mm A"
                //     )
                // );
                const Description = String(item?.TicketDescription
                );


                const text_data = term.toLowerCase();
                // console.log(text_data, "neeed");
                return (
                    TicketId.indexOf(text_data) > -1 ||
                    DepartmentName.toLowerCase().indexOf(text_data) > -1 ||
                    ProductName.toLowerCase().indexOf(text_data) > -1 ||
                    IssueName.toLowerCase().indexOf(text_data) > -1 ||
                    Description.toLowerCase().indexOf(text_data) > -1 ||
                    PriorityLevel.toLowerCase().indexOf(text_data) > -1

                    // AssignedBy.toLowerCase().indexOf(text_data) > -1 ||

                );
            });
            SetSearchAll(ticketData);
        } else {
            SetSearchAll(All);
        }
    }
    useEffect(() => {
        Alltable()
    }, [])

    return (
        <>
            <Grid item
                lg={2}
                sm={2}
                xs={12}
                md={2}>
                <Box
                    id="searcBox"
                    sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        padding: "3px",
                        background: "#FFFFFF",
                        borderWidth: 1,
                        borderStyle: "solid",
                        borderColor: "#2323234D",
                        borderRadius: "10px",
                        textAlign: "center",
                    }}
                >
                    <input
                        placeholder="Search..."
                        type="text"
                        className="searcInput"
                        id="searchInput"
                        style={{
                            outline: "none",
                            backgroundColor: "#FFFFFF",
                            border: "none",
                            width: '100%'
                        }}
                        autoComplete="off"
                        onChange={(e) => filterList(e.target.value)}
                    />

                    <GridSearchIcon style={{ color: "#2323234D" }} />
                </Box>
            </Grid>
            <div style={{ height: 480, width: "100%", marginTop: '10px' }}>
                <DataGrid
                    sx={{
                        boxShadow: 2,
                        "& .MuiDataGrid-cell:hover": {
                            color: "primary.main",
                        },
                        "& .super-app-theme--header": { color: "#0071BC", fontSize: "16px", fontWeight: '600' },
                        ".MuiTablePagination-displayedRows": {
                            margin: "0px",
                        },
                        ".MuiTablePagination-selectLabel": {
                            margin: "0px",
                        },
                    }}
                    initialState={{
                        ...SearchAll.initialState,
                        pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    rows={SearchAll}
                    columns={columns}
                    rowHeight={38}
                    pageSize={10}
                    getRowId={(row) => row.TicketId}
                    sortModel={sortModel}
                    onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                // checkboxSelection
                />


            </div>
        </>
    )
}

export default All
const styles = {
    searchInput: {
        outline: "none",
        backgroundColor: "#FFFFFF",
        border: "none",
    },
}